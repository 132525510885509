import React, { createContext, useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { Auth } from 'types/auth';
import { Roles } from 'types/user';

import api from 'services/api';

import usePersistedState from 'hooks/usePersistedState';

import { toast } from 'shared/toast';

import { Account } from 'types/account';

export type { Auth } from 'types/auth';

const LocalStorageKey = 'cursology:auth';

const AuthContext = createContext({} as Auth);

interface AccountsAuth {
  id: number;
  name: string;
  roles: Roles[];
}

export interface UserAuth {
  id: number;
  name: string;
  email: string;
  status: string;
  created_at: string;
  updated_at: string;
  roles: Roles[];
  slug: string;
  accounts: AccountsAuth[];
}

export const AuthProvider: React.FC = ({ children }) => {
  const location = useLocation();

  const [loading] = useState<boolean>(false);
  const [authenticated, setAuthenticated] = useState<boolean>(false);
  const [token, setToken] = usePersistedState<string | null>(
    LocalStorageKey,
    null,
  );

  const [_, setAccount] = usePersistedState<Account | null>(
    '_managed_account',
    null,
  );

  const [validing, setValiding] = useState<boolean>(true);

  const [user, setUser] = useState<UserAuth>({} as UserAuth);

  const getAuth = useCallback(async () => {
    setValiding(true);

    if (location.pathname === 'callback') {
      return;
    }

    try {
      const { data: userInfo } = await api.get('/auth');

      const loadedUser: UserAuth = userInfo.data;

      const isNewUser =
        loadedUser.roles.length === 0 && loadedUser.accounts.length === 0;

      const userData = {
        ...loadedUser,
        ...(isNewUser && { roles: ['default'] }),
      };

      setUser(userData);

      setAuthenticated(true);
    } catch (err) {
      setAuthenticated(false);

      delete api.defaults.headers.Authorization;

      toast({
        description: 'Sessão expirada! Faça login novamente.',
        status: 'warning',
      });
    } finally {
      setValiding(false);
    }
  }, []);

  const handleLogout = useCallback(async (): Promise<void> => {
    setValiding(true);

    try {
      await api.delete('/auth');
    } finally {
      setValiding(false);

      setAuthenticated(false);

      setToken(null);

      setUser({} as UserAuth);

      setAccount(null);

      delete api.defaults.headers.Authorization;
    }
  }, [setToken]);

  const checkRoles = useCallback(
    (roles: Roles[]): boolean => {
      if (roles.some((role) => user?.roles?.includes(role))) {
        return true;
      }

      const verifyHasRole = (rolesAccount: Roles[]): boolean => {
        return rolesAccount.some((roleAccount) => roles.includes(roleAccount));
      };

      if (user?.accounts?.some((account) => verifyHasRole(account.roles))) {
        return true;
      }

      return false;
    },
    [user.roles, user.accounts],
  );

  useEffect(() => {
    if (token) {
      api.defaults.headers.Authorization = `Bearer ${token}`;

      getAuth();
    } else {
      setValiding(false);

      setAuthenticated(false);

      setToken(null);

      setUser({} as UserAuth);

      delete api.defaults.headers.Authorization;
    }
  }, [token, getAuth, setToken]);

  return (
    <AuthContext.Provider
      value={{
        authenticated,
        user,
        validing,
        token,
        loading,
        handleLogout,
        checkRoles,
        getAuth,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
